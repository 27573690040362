import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/auth_slice/login_user_slice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export const AppTopbar = (props) => {
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const history = useHistory();
  const dispatch = useDispatch();

  const signoutConfirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      acceptClassName: "p-button p-button p-button-sm width-80",
      accept: () => {
        dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
      },
      reject: () => { },
    });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/settings-teller",
    });
  };

  return (
    <>
      <ConfirmDialog />

      <div className="layout-topbar">
        <div className="layout-topbar-ellipsis">
          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>
          {props.currentRouteLabel && (
            <div className="layout-topbar-heading">
              {props.currentRouteLabel}
            </div>
          )}
        </div>

        <div className="layout-topbar-user">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Hello, &nbsp; {user?.user?.name} &nbsp;

            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" onClick={handleCancel}>
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" onClick={() => {
                  signoutConfirm();
                }}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
};
