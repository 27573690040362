import React, { useState, useEffect } from "react";
import SlotForm from "./slotForm";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { ErrorMessage } from "formik";

const CourtForm = ({ court, courtIndex, sportIndex, setFieldValue }) => {
  const [maxSlots, setMaxSlots] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedDays, setSelectedDays] = useState(
    court.slots.daysAvailableArray || []
  );

  const [additionalCharges, setAdditionalCharges] = useState("");

  useEffect(() => {
    setSelectedDays(court.slots.daysAvailableArray || []);
  }, [court.slots.daysAvailableArray]);

  const days = [
    { name: "Monday", code: "1" },
    { name: "Tuesday", code: "2" },
    { name: "Wednesday", code: "3" },
    { name: "Thursday", code: "4" },
    { name: "Friday", code: "5" },
    { name: "Saturday", code: "6" },
    { name: "Sunday", code: "7" },
  ];

  const handleDayChange = (dayCode) => {
    const updatedDays = selectedDays.includes(dayCode)
      ? selectedDays.filter((day) => day !== dayCode)
      : [...selectedDays, dayCode];

    setSelectedDays(updatedDays);

    setFieldValue(
      `availableSports[0].courts[${courtIndex}].slots.daysAvailable`,
      updatedDays.join(",")
    );

    setFieldValue(
      `availableSports[0].courts[${courtIndex}].slots.daysAvailableArray`,
      updatedDays
    );
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        let base64String = reader.result;
        if (base64String.includes("base64,")) {
          base64String = base64String.split("base64,")[1];
        }
        setFileName(file.name);
        setIsImageEdit(true);
        setFieldValue(
          `availableSports[0].courts[${courtIndex}].courtImage`,
          base64String
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const showAdditionalAmountField =
    selectedDays.includes("6") || selectedDays.includes("7");

  const handleAdditionalChargesChange = (e) => {
    const value = e.target.value;
    const validNumber = parseFloat(value);

    const formattedValue = isNaN(validNumber)
      ? "0.0000"
      : validNumber.toFixed(4);

    setAdditionalCharges(formattedValue);
  };

  useEffect(() => {
    const startTime = court.slots.startTime;

    const validStartTime =
      startTime instanceof Date ? startTime : new Date(startTime);

    const slotDuration = Number(court.slots.timePerSlot);
    const refreshTime = Number(court.slots.refreshTime);

    if (validStartTime && slotDuration && refreshTime) {
      const startHours = validStartTime.getHours();
      const startMinutes = validStartTime.getMinutes();
      const timeUntilMidnight = (24 - startHours) * 60 - startMinutes;
      const totalSlotTime = slotDuration + refreshTime;
      const maxSlots = Math.floor(timeUntilMidnight / totalSlotTime);

      setMaxSlots(maxSlots);
      setErrorMessage("");
    } else {
      setMaxSlots(null);
    }
  }, [
    court.slots.startTime,
    court.slots.timePerSlot,
    court.slots.refreshTime,
    courtIndex,
    setFieldValue,
  ]);

  const formatTimeToString = (date) => {
    if (!(date instanceof Date)) return "";

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
    return formattedTime;
  };

  const handleTotalSlotsChange = (e) => {
    const totalSlots = Number(e.target.value);
    const { startTime, timePerSlot, refreshTime } = court.slots;

    if (!startTime || !timePerSlot || !refreshTime) {
      setErrorMessage("You need to fill timing entries first.");
    } else if (totalSlots > maxSlots) {
      setErrorMessage(`Maximum ${maxSlots} slots can be added.`);
    } else {
      setErrorMessage("");

      const slots = [];
      let currentStartTime = new Date(startTime);

      for (let i = 0; i < totalSlots; i++) {
        let slotEndTime = new Date(
          currentStartTime.getTime() + timePerSlot * 60000
        );
        const validNumber = parseFloat(additionalCharges);
        const formattedAdditionalCharges = isNaN(validNumber)
          ? 0.0
          : validNumber.toFixed(4);

        slots.push({
          slotName: `Slot ${i + 1}`,
          slotTime: `${formatTimeToString(
            currentStartTime
          )} - ${formatTimeToString(slotEndTime)}`,
          slotPrice: parseFloat(court.slots.amountPerSlot) || 0,
          isActive: false,
          additionalCharges: formattedAdditionalCharges,
        });

        currentStartTime = new Date(
          slotEndTime.getTime() + refreshTime * 60000
        );
      }

      setFieldValue(
        `availableSports[0].courts[${courtIndex}].slots.totalSlots`,
        totalSlots
      );

      setFieldValue(
        `availableSports[0].courts[${courtIndex}].slotsData`,
        slots
      );
    }
  };

  function timeStringToDate(timeString) {
    if (typeof timeString !== "string") {
      return timeString;
    }

    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(0);

    return date;
  }

  return (
    <div>
      <div className="p-fluid formgrid grid">
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Court Name <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={30}
            keyfilter={/^[a-zA-Z\s]*$/}
            placeholder="Court name"
            name={`availableSports[0].courts[${courtIndex}].courtName`}
            value={court.courtName}
            onChange={(e) =>
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].courtName`,
                e.target.value
              )
            }
          />
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].courtName`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label
            htmlFor={`availableSports[0].courts[${courtIndex}].courtImage`}
          >
            Court Photo <span className="error-indicator required">*</span>
          </label>
          <div className="file-input-main-form">
            <input
              type="text"
              name="courtImage"
              value={
                court.courtImage && !isImageEdit
                  ? `${court.courtImage}`
                  : fileName || "No file chosen"
              }
              readOnly
              placeholder="No file chosen"
            />
            <label
              className="choose-file-button"
              htmlFor={`courtImage-${courtIndex}`}
            >
              Choose File
            </label>
            <input
              id={`courtImage-${courtIndex}`}
              name={`availableSports[0].courts[${courtIndex}].courtImage`}
              type="file"
              accept="image/*"
              className="choose-image-none"
              onChange={handleFileUpload}
            />
          </div>

          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].courtImage`}
            component="small"
            className="error-message"
          />
        </div>

        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Amount Per Slot (PKR){" "}
            <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={5}
            keyfilter={/^[0-9]*\.?[0-9]*$/}
            placeholder="Amount per slot"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.amountPerSlot`}
            value={court.slots.amountPerSlot}
            onChange={(e) =>
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.amountPerSlot`,
                e.target.value
              )
            }
          />
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.amountPerSlot`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Slot Timing Per Court (min){" "}
            <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={3}
            keyfilter="pint"
            placeholder="Slot timing per court"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.timePerSlot`}
            value={court.slots.timePerSlot}
            onChange={(e) => {
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.timePerSlot`,
                e.target.value
              );
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.timePerSlot`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Start Time <span className="error-indicator required">*</span>
          </label>
          <Calendar
            timeOnly
            hourFormat="12"
            placeholder="Start time"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.startTime`}
            value={
              court.slots.startTime
                ? timeStringToDate(court?.slots?.startTime)
                : court.slots.startTime
            }
            onChange={(e) => {
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.startTime`,
                e.target.value
              );
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.startTime`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Refresh time (min){" "}
            <span className="error-indicator required">*</span>
          </label>
          <InputText
            placeholder="Refresh time"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.refreshTime`}
            value={court.slots.refreshTime}
            onChange={(e) => {
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.refreshTime`,
                e.target.value
              );
              setFieldValue(
                `availableSports[0].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.refreshTime`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Total Slots <span className="error-indicator required">*</span>
          </label>
          <InputText
            placeholder="Total time"
            name={`availableSports[0].courts[${courtIndex}].slots.totalSlots`}
            type="number"
            value={court.slots.totalSlots || ""}
            onChange={handleTotalSlotsChange}
          />
          {(maxSlots !== null || errorMessage) && (
            <small
              style={{
                display: "block",
                color: errorMessage ? "red" : "green",
              }}
            >
              {errorMessage ||
                `Maximum ${
                  maxSlots ? maxSlots : court.slots.totalSlots
                } slots can be added.`}
            </small>
          )}
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.totalSlots`}
            component="small"
            className="error-message"
          />
        </div>
        {court.slots.totalSlots > 0 && (
          <SlotForm
            slotsData={court.slotsData}
            setFieldValue={setFieldValue}
            courtIndex={courtIndex}
            sportIndex={sportIndex}
          />
        )}
        <div className="field md:col-6 lg:col-12 xl:col-12 responsive-col">
          <label>
            Available Days <span className="error-indicator required">*</span>
          </label>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {days.map((day) => (
              <div
                key={day.code}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  inputId={day.code}
                  value={day.code}
                  checked={selectedDays.includes(day.code)}
                  onChange={() => handleDayChange(day.code)}
                  className="checkbox-courts"
                />
                <label
                  htmlFor={day.code}
                  className="p-checkbox-label"
                  style={{ marginLeft: "10px", marginTop: "10px" }}
                >
                  {day.name}
                </label>
              </div>
            ))}
          </div>
          <ErrorMessage
            name={`availableSports[0].courts[${courtIndex}].slots.daysAvailable`}
            component="small"
            className="error-message"
          />
        </div>
        {showAdditionalAmountField && (
          <>
            <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
              <label>Additional Amount (PKR)</label>
              <InputText
                keyfilter={/^[0-9]*\.?[0-9]*$/}
                maxLength={5}
                placeholder="Additional amount"
                name={`availableSports[${sportIndex}].courts[${courtIndex}].slotsData.additionalCharges`}
                value={court.slotsData.additionalCharges}
                onChange={handleAdditionalChargesChange}
              />
            </div>
          </>
        )}
      </div>
      {showAdditionalAmountField && (
        <p style={{ fontSize: "12px", color: "green" }}>
          Note: Amount + additional amount applies only on weekends.
        </p>
      )}
    </div>
  );
};

export default CourtForm;
