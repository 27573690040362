import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  createSportsComplex,
  getSportsComplexList,
} from "../../../../../redux/zsports_slice/zsports_slice";
import { getSportsComplexes } from "../../../../../redux/portal_slice";
import Loader from "../../../../components/loader";
import { Dropdown } from "primereact/dropdown";
import SportForm from "./sportForm";
import { format, parseISO, isValid } from "date-fns";
import validationSchema from "./schema";
import { ConfirmDialog } from "primereact/confirmdialog";

const formatTime = (time) => {
  if (time instanceof Date) {
    return format(time, "HH:mm:ss");
  }

  if (typeof time === "string") {
    if (time.includes("T")) {
      const parsedDate = parseISO(time);
      if (isValid(parsedDate)) {
        return format(parsedDate, "HH:mm:ss");
      }
    }

    if (/^\d{2}:\d{2}:\d{2}$/.test(time)) {
      return time;
    }
  }

  throw new Error(`Invalid time format: ${time}`);
};

const EditAddForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.loginUser);

  const portalReducer = useSelector((state) => state.portal || {});
  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );

  const { portalList } = portalReducer;
  const { sportsComplexList } = sportsComplexReducer;
  const loading = portalReducer.loading || sportsComplexReducer.loading;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedSportImage, setSelectedSportImage] = useState("");

  const [initialValues, setInitialValues] = useState({
    sportComplexId: "",
    mobileNumber: "",
    complexName: "",
    cityId: "",
    address: "",
    location: "",
    image: "",
    availableSports: [
      {
        sportId: "",
        courts: [
          {
            courtName: "",
            courtImage: "",
            slots: {
              timePerSlot: "",
              daysAvailable: "",
              daysAvailableArray: [],
              refreshTime: "",
              amountPerSlot: "",
              startTime: "",
              totalSlots: "",
            },
            slotsData: [
              {
                slotTime: "",
                slotName: "",
                slotPrice: "",
                additionalCharges: "",
                isActive: true,
              },
            ],
          },
        ],
      },
    ],
  });

  useEffect(() => {
    dispatch(getSportsComplexList());
    if (user && user.user && user.token) {
      dispatch(
        getSportsComplexes({
          token: user.token,
        })
      );
    }
  }, [dispatch, user]);

  // useEffect(() => {
  //   if (portalList?.length > 0) {
  //     const portalData = portalList[0];
  //     setInitialValues(portalData);

  //     console.log(portalData, "data");
  //   }
  // }, [portalList]);

  useEffect(() => {
    if (portalList?.length > 0) {
      const portalData = { ...portalList[0] };
      if (
        !portalData.availableSports ||
        portalData.availableSports.length === 0
      ) {
        portalData.availableSports = [
          {
            sportId: "",
            courts: [
              {
                courtName: "",
                courtImage: "",
                slots: {
                  timePerSlot: "",
                  daysAvailable: "",
                  daysAvailableArray: [],
                  refreshTime: "",
                  amountPerSlot: "",
                  startTime: "",
                  totalSlots: "",
                },
                slotsData: [
                  {
                    slotTime: "",
                    slotName: "",
                    slotPrice: "",
                    additionalCharges: "",
                    isActive: true,
                  },
                ],
              },
            ],
          },
        ];
      }

      setInitialValues(portalData);
    }
  }, [portalList]);

  const handleSubmit = async (values) => {
    console.log("Success:", values);
    try {
      const formattedValues = {
        ...values,
        availableSports: values.availableSports.map((sport) => ({
          ...sport,
          courts: sport.courts.map((court) => ({
            ...court,
            slots: {
              ...court.slots,
              startTime: formatTime(court.slots.startTime),
            },
          })),
        })),
      };

      const response = await dispatch(
        createSportsComplex(formattedValues)
      ).unwrap();

      if (response?.responseCode === "00") {
        showSuccessDialog();
      } else {
        console.error("Response Error:", response?.responseDescription);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setShowDialog(false);
    history.push({
      pathname: "/schedule",
    });
  };

  const showSuccessDialog = () => {
    setShowDialog(true);
  };

  const dialogFooter = (
    <div>
      <Button label="Okay" icon="pi pi-check" onClick={handleCancel} />
    </div>
  );

  const handleSportChange = (e, setFieldValue) => {
    const selectedSport = sportsComplexList.find(
      (sport) => sport.id === e.value
    );
    setSelectedSportImage(selectedSport ? selectedSport.sportImage : "");
    setFieldValue("availableSports[0].sportId", e.value);
  };

  return (
    <>
      <ConfirmDialog
        header="Success"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <div style={{ textAlign: "center", lineHeight: "1.5" }}>
            Successfully added sports complex.
            <br />
            It is now awaiting approval from the checker.
          </div>
        }
      />
      <div className="main-form">
        {loading && <Loader />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-heading">
                <h5>Add Courts in Sports Complex</h5>
              </div>

              <div className="p-fluid formgrid grid">
                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col disabled-classes">
                  <label>City Name</label>
                  <InputText
                    maxLength={30}
                    keyfilter={/^[a-zA-Z\s]*$/}
                    name="cityId"
                    value={values.cityName || ""}
                    disabled
                  />
                </div>
                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col disabled-classes">
                  <label>Complex Name</label>
                  <InputText
                    maxLength={30}
                    keyfilter={/^[a-zA-Z\s]*$/}
                    name="complexName"
                    value={values.complexName}
                    disabled
                  />
                </div>
                <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col disabled-classes">
                  <label>Complex Image</label>
                  <div className="file-input-main-form">
                    <input
                      type="text"
                      name="image"
                      value={values.image || "Choose complex image"}
                      readOnly
                    />
                    <label
                      htmlFor="complexImageUpload"
                      className="choose-file-button"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
                <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
                  <label>
                    Address <span className="error-indicator required">*</span>
                  </label>
                  <InputText
                    maxLength={30}
                    placeholder="Address"
                    name="address"
                    value={values.address}
                    onChange={(e) => setFieldValue("address", e.target.value)}
                  />
                  <ErrorMessage
                    name="address"
                    component="small"
                    className="error-message"
                  />
                </div>
                <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
                  <label>
                    Location <span className="error-indicator required">*</span>
                  </label>
                  <InputText
                    maxLength={100}
                    placeholder="Location"
                    name="location"
                    value={values.location}
                    onChange={(e) => setFieldValue("location", e.target.value)}
                  />
                  <ErrorMessage
                    name="location"
                    component="small"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="p-fluid ">
                <div className="form-heading">
                  <h5>Sport</h5>
                </div>
                <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                  <label>
                    Sport Name{" "}
                    <span className="error-indicator required">*</span>{" "}
                  </label>
                  <Dropdown
                    filter
                    name="availableSports[0].sportId"
                    placeholder="Select Sport"
                    value={values.availableSports[0]?.sportId}
                    options={sportsComplexList.map((sport) => ({
                      label: sport.sportName,
                      value: sport.id,
                    }))}
                    onChange={(e) => handleSportChange(e, setFieldValue)}
                  />
                  <ErrorMessage
                    name="availableSports[0].sportId"
                    component="small"
                    className="error-message"
                  />
                </div>
              </div>
              <SportForm
                sport={values.availableSports[0]}
                setFieldValue={setFieldValue}
                selectedSportImage={selectedSportImage}
              />
              <div className="flex buttons">
                <Button
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={handleCancel}
                />
                <Button label="Submit" className="p-button" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditAddForm;
