import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  portalList: [],
  loading: false,
  error: null,
  updateSuccess: null,
};

// Slice definition
const portalReducer = createSlice({
  name: "portal",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.portalList = action.payload;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = action.payload;
        toast.success("Profile details updated successfully");
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })

      .addCase(getSportsComplexes.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.portalList = action.payload.data.data;
      })
      .addCase(getSportsComplexes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reducer and actions
export default portalReducer.reducer;
export const { resetChangeStatus } = portalReducer.actions;

export const getMyProfile = createAsyncThunk(
  "portal/getMyProfile",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.portalPath}/GetMyProfile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const getSportsComplexes = createAsyncThunk(
  "portal/getSportsComplexes",
  async ({ token, pageNo = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.portalPath}/GetSportsComplexes`,
        {
          PageNo: pageNo,
          PageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);



export const updateProfile = createAsyncThunk(
  "portal/updateProfile",
  async (portalData, { rejectWithValue }) => {
    try {
      const { token, ...data } = portalData;
      const response = await Axios.put(
        `${config.baseUrl}${config.portalPath}/UpdateMyProfile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
