import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function ProfileBarChart({ dashboardList }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const monthlyActiveUsers = dashboardList?.monthlyActiveUsers || [];
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const activeUsersData = months.map(month => {
      const user = monthlyActiveUsers.find(user => user.month === month);
      return user ? user.count : 0;
    });

    const colors = [
      "#FFB6C1",
      "#FFDAC1",
      "#FFE4B5",
      "#E0FFD6",
      "#B2DFDB",
      "#BFD3C1",
      "#FFB3E6",
      "#C2C2F0",
      "#D3C6E0",
      "#F6E8B1",
      "#C4E1F0",
      "#F0F8FF",
    ];

    const data = {
      labels: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      datasets: [
        {
          label: "Active Users",
          data: activeUsersData,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
          barThickness: 40,
          maxBarThickness: 40,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          stacked: false,
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          labels: {
            usePointStyle: false,
            boxWidth: 15,
            padding: 30,
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },
      responsive: true,
      aspectRatio: 2,
    };

    setChartData(data);
    setChartOptions(options);
  }, [dashboardList]);

  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
