import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import { loginUser } from "../../../redux/auth_slice/login_user_slice";
import logo from "../../../../assets/sports_assests/z-logo.png";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function LoginScreen() {
  const { loading } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
      .required("PIN is required.")
      .length(4, "PIN must be 4 digits long."),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
    }),
    onSubmit: async (data) => {
      dispatch(loginUser(data)).then((res) => {
        if (res?.payload?.token) {
          toast.success("Successfully Login");
          return history.push("/");
        } else {
          toast.error(res?.payload?.responseDescription);
        }

        history.push("/login");
      });
    },
  });

  const handleRedirect = () => {
    history.push("/register");
  };

  return (
    <>
      <div className="auth_main">
        <div className="content">
          <img src={logo} className="logo-img" />
          <div className="auth_login_box">
            <h2 className="auth-welcome mt-3 text-align-center">Login</h2>
            <form
              className=" p-fluid justify-content-center align-items-left mt-5"
              onSubmit={formik.handleSubmit}
            >
              <CustomInputField
                label="Mobile Number"
                iden="mobileNumber"
                formik={formik}
                placeHolder="Mobile Number"
                type="text"
                maxLength={11}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("mobileNumber", e.target.value);
                }}
                 icon="pi-mobile"
              />
              <CustomInputField
                label="Pin"
                iden="pin"
                formik={formik}
                placeHolder="Pin"
                type="password"
                feedback={false}
                maxLength={4}
                onKeyUp={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("pin", e.target.value);
                }}
                icon="pi-lock"
              />
              <br />
              <Button
                type="submit"
                className="customButton"
                label="Login"
                loading={loading}
              />
             
            </form>
            <p className="text-center mt-2 an-account">
              If you have an account? <a onClick={handleRedirect}>Signup here</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
