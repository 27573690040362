import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <ProgressSpinner
        className="spinner-loader"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </div>
  );
};

export default Spinner;
