import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import stats1 from "../../../../../assets/sports_assests/stats.png";
import stats2 from "../../../../../assets/sports_assests/stats1.png";
import stats3 from "../../../../../assets/sports_assests/stats2.png";
import stats4 from "../../../../../assets/sports_assests/bg.png";
import Notification from "../../../../../assets/sports_assests/notification.png";
import OverAllBarChart from "./overallbar-chart";
import ProfileBarChart from "./profilebar-chart";
import ScheduleBarChart from "./schedulebar-chart";
import ComplexBarChart from "./complexbar-chart";
import { getAdminDashboardStats } from "../../../../redux/dashboard_slice/dashboard_slice";
import Spinner from "../../../components/spinner";
import { getRegistrationRequestsCount } from "../../../../redux/auth_slice/register_user_slice";

export default function DashboardCheckerScreen() {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboard || {});
  const { loading, dashboardList } = dashboardReducer;

  const registerSliceReducer = useSelector((state) => state.register || {});
  const { registrationUserCount } = registerSliceReducer;

  useEffect(() => {
    dispatch(getAdminDashboardStats({}));
    dispatch(getRegistrationRequestsCount({ status: "Pending" }));
  }, [dispatch]);

  const tilesData = [
    {
      title: "Active Users",
      value: dashboardList?.activeUsersCount,
      backgroundImage: stats1,
      loading: loading,
    },
    {
      title: "Amount Collection",
      value: (
        <>
        <span className="italic">PKR &nbsp;</span>
        {new Intl.NumberFormat().format(dashboardList?.amountCollected)}
      </>
      ),
      backgroundImage: stats2,
      loading: loading,
    },
    {
      title: "Total Complexes",
      value: dashboardList?.totalComplexes,
      backgroundImage: stats4,
      loading: loading,
    },
    {
      title: "Total Bookings",
      value: dashboardList?.totalBookings,
      backgroundImage: stats3,
      loading: loading,
    },
  ];

  return (
    <>
      {registrationUserCount > 0 && (
        <div className="notification-container">
          <img
            className="notification-image"
            src={Notification}
            alt="Notification"
          />
          <div className="notification-text">
            There are{" "}
            <span className="registration-count">{registrationUserCount}</span>{" "}
            new registration request{registrationUserCount !== 1 ? "s" : ""}{" "}
            awaiting your approval.
            <a href="#/register-checker" className="link">
              Approved Now
            </a>
          </div>
        </div>
      )}
      <div className="stats-content">
        <div className="grid titles-grid">
          {tilesData.map((tile, index) => (
            <div className="md:col-12 lg:col-3 mobile-graphs" key={index}>
              <div
                className="tiles"
                style={{ backgroundImage: `url(${tile.backgroundImage})` }}
              >
                {tile.loading ? (
                  <Spinner />
                ) : (
                  <>
                    <h2>{tile.title}</h2>
                    <h1>{tile.value}</h1>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="grid">
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>OverAll</h6>
                  <OverAllBarChart dashboardList={dashboardList} />
                </>
              )}
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>Profile Management</h6>
                  <ProfileBarChart dashboardList={dashboardList} />
                </>
              )}
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>Schedule Management</h6>
                  <ScheduleBarChart dashboardList={dashboardList} />
                </>
              )}
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>Complex Management</h6>
                  <ComplexBarChart dashboardList={dashboardList} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
