import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import CustomImagePreview from "../../../components/custom_imagepreview";
import {
  getRegistrationRequests,
  approveUser,
  rejectUser,
} from "../../../../redux/auth_slice/register_user_slice";
import { useHistory } from "react-router-dom";
import { renderTextWithTooltip } from "../../../components/helper";

export default function RegisterCheckerScreen() {
  const dispatch = useDispatch();
  const history = useHistory();

  const registerSliceReducer = useSelector((state) => state.register || {});
  const { registrationUserList, loading } = registerSliceReducer;

  const [globalFilter, setGlobalFilter] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isApproveAction, setIsApproveAction] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [currentTab, setCurrentTab] = useState("Pending");
  const [remarksError, setRemarksError] = useState("");
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const fetchUserList = async (tab) => {
    let status = currentTab;

    if (tab === "Pending") {
      status = "Pending";
    } else if (tab === "Approved") {
      status = "Approved";
    } else if (tab === "Rejected") {
      status = "Rejected";
    }

    dispatch(getRegistrationRequests({ status }));
  };

  useEffect(() => {
    fetchUserList();
  }, [currentTab]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleViewNavigation = (rowData) => {
    sessionStorage.setItem("viewDetailsData", JSON.stringify(rowData));
    history.push(`/register-checker/view-details/${rowData.id}`);
  };

  const handleApproveClick = (rowData) => {
    setCurrentRowData(rowData);
    setIsApproveAction(true);
    setShowConfirmDialog(true);
  };

  const handleRejectClick = (rowData) => {
    setCurrentRowData(rowData);
    setIsApproveAction(false);
    setShowConfirmDialog(true);
  };

  const handleApproveConfirm = () => {
    if (!currentRowData) return;

    dispatch(
      approveUser({
        id: currentRowData.id,
      })
    )
      .then(() => {
        toast.success("Request approved successfully!");
        setShowConfirmDialog(false);
        fetchUserList(currentTab);
      })
      .catch(() => {
        toast.error("Approval failed");
      });
  };

  const handleRejectConfirm = () => {
    if (!currentRowData) return;

    dispatch(
      rejectUser({
        id: currentRowData.id,
        remarks,
      })
    )
      .then(() => {
        toast.success("Request rejected successfully!");
        setShowConfirmDialog(false);
        setRemarks("");
        fetchUserList(currentTab);
      })
      .catch(() => {
        toast.error("Rejection failed");
      });
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        {currentTab === "Pending" && (
          <>
            <Button
              className="p-button-rounded p-button-success p-0 mr-2 action-button"
              onClick={() => handleApproveClick(rowData)}
              aria-label="Approve"
              icon="pi pi-check"
              label=""
            />
            <Button
              className="p-button-rounded p-button-danger p-0 mr-5 action-button"
              onClick={() => handleRejectClick(rowData)}
              aria-label="Reject"
              icon="pi pi-times"
              label=""
            />
          </>
        )}
        <Button
          className="secondary-btn"
          onClick={() => handleViewNavigation(rowData)}
          aria-label="View Details"
        >
          Details
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.image} />
      </div>
    );
  };

  const columnsData = [
    {
      field: "serialNumber",
      header: "Sr. No",
      body: (_, col) => col.rowIndex + 1,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      field: "mobileNumber",
      header: "Mobile Number",
      body: (rowData) => (rowData.mobileNumber ? rowData.mobileNumber : "N/A"),
    },
    {
      header: "City",
      field: "cityName",
      body: (rowData) => (rowData.cityName ? rowData.cityName : "N/A"),
    },
    {
      header: "Complex Name",
      field: "complexName",
      body: (rowData) =>
        renderTextWithTooltip(rowData, "complexName", "bottom"),
    },
    {
      header: "Status",
      body: (rowData) =>
        currentTab === "All" || rowData.status === currentTab ? (
          <Tag
            className={
              rowData.status === "Pending"
                ? "p-tag-contrast"
                : rowData.status === "Approved"
                ? "p-tag-success"
                : rowData.status === "Rejected"
                ? "p-tag-danger"
                : "p-tag-default"
            }
          >
            {rowData.status}
          </Tag>
        ) : null,
    },
    {
      header: currentTab === "Rejected" ? "Remarks" : null,
      body: (rowData) =>
        currentTab === "Rejected" && rowData.status === "Rejected"
          ? renderTextWithTooltip(rowData, "remarks")
          : null,
    },

    {
      header: "Complex Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const filteredColumnsData = columnsData.filter(
    (column) => column.header !== null
  );

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  return (
    <>
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        message={
          isApproveAction ? (
            "Are you sure you want to approve this user?"
          ) : (
            <div>
              <p className="text-center">
                Are you sure you want to reject this user?
                <br /> If yes, please enter the reason in the Remarks section.
              </p>
              <div>
                <label className="mb-2">
                  Remarks <span className="required error-indicator">*</span>
                </label>
                <InputTextarea
                  maxLength={100}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Enter remarks"
                  rows={5}
                  cols={30}
                />
                {remarksError && <div className="p-error">{remarksError}</div>}
              </div>
            </div>
          )
        }
        header="Confirmation"
        icon="pi pi-question-circle"
        accept={isApproveAction ? handleApproveConfirm : handleRejectConfirm}
        reject={() => setShowConfirmDialog(false)}
        footer={
          <div className="flex dialog-footer">
            <Button
              label="Cancel"
              className="p-button secondary-button"
              onClick={() => setShowConfirmDialog(false)}
            />
            <Button
              label="Submit"
              className="p-button"
              onClick={() => {
                if (isApproveAction) {
                  handleApproveConfirm();
                  setShowConfirmDialog(false);
                } else {
                  if (!remarks) {
                    setRemarksError("Remarks are required");
                  } else {
                    handleRejectConfirm();
                    setShowConfirmDialog(false);
                  }
                }
              }}
            />
          </div>
        }
      />

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>
      </div>

      <div className="mb-4 tab-buttons">
        <Button
          onClick={() => handleTabChange("Pending")}
          className={`p-button ${
            currentTab === "Pending" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Pending
        </Button>
        <Button
          onClick={() => handleTabChange("Approved")}
          className={`p-button ${
            currentTab === "Approved" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Approved
        </Button>
        <Button
          onClick={() => handleTabChange("Rejected")}
          className={`p-button ${
            currentTab === "Rejected" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Rejected
        </Button>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={registrationUserList}
          paginator
          rows={rows}
          first={first}
          onPage={onPageChange}
          globalFilter={globalFilter}
          emptyMessage="No records found."
          className="custom-data-table"
        >
          {filteredColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
              style={{ minWidth: "12rem" }}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
