import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

// Thunk for registering a user
export const registerUser = createAsyncThunk(
  "register/registerUser",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.registerUserPath}/register`,
        body
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk for getting registration requests from the new API
export const getRegistrationRequests = createAsyncThunk(
  "register/getRegistrationRequests",
  async ({ status }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}${config.registerUserPath}/RegistrationRequests`,
        {
          params: { status },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk for approving a user
export const approveUser = createAsyncThunk(
  "register/approve",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.registerUserPath}/RegistrationRequestsApprove`,
        { id }
      );

      if (response.data.success) {
        return id;
      } else {
        throw new Error("Approval failed");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const rejectUser = createAsyncThunk(
  "register/reject",
  async ({ id, remarks, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.registerUserPath}/RegistrationRequestsReject`,
        { id, remarks },
      );

      if (response.data.success) {
        return { id, remarks };
      } else {
        throw new Error("Rejection failed");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getRegistrationRequestsCount = createAsyncThunk(
  "register/getRegistrationRequestsCount",
  async ({ status }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}${config.registerUserPath}/RegistrationRequestsCount`,
        {
          params: { status },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message;
      return rejectWithValue(errorMessage);
    }
  }
);


const registerSliceReducer = createSlice({
  name: "register",
  initialState: {
    regiterUser: null,
    registrationUserList: [],
    registrationUserCount: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.regiterUser = action.payload;
        localStorage.setItem("regiterUser", JSON.stringify(action.payload));
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRegistrationRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRegistrationRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.registrationUserList = action.payload.data;
      })
      .addCase(getRegistrationRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getRegistrationRequestsCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRegistrationRequestsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.registrationUserCount = action.payload;
      })
      .addCase(getRegistrationRequestsCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(approveUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveUser.fulfilled, (state, action) => {
        state.loading = false;
        state.approveSuccess = true;
        state.registrationUserList = state.registrationUserList.filter(
          (complex) => complex.id !== action.payload
        );
      })
      .addCase(approveUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(rejectUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectUser.fulfilled, (state, action) => {
        state.loading = false;
        state.rejectSuccess = true;
        state.registrationUserList = state.registrationUserList.filter(
          (complex) => complex.id !== action.payload
        );
      })
      .addCase(rejectUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default registerSliceReducer.reducer;
export const { resetChangeStatus } = registerSliceReducer.actions;
