const config = {
  baseUrl: "https://qa-zsports.appinsnap.com/Z-SportService",

  authPath: "/api/Account/login",
  registerUserPath: "/api/Account",
  usersPath: "/api/Users",
  tellerAppPath: "/api/TellerApp",
  checkerAppPath: "/api/Admin",
  dashboardPath: "/api/AdminDashboard",
  portalPath: "/api/PortalApp"
};

export default config;
