import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSportComplexById } from "../../../../../redux/zsports_slice/zsports_slice";
import { useHistory } from "react-router-dom";
import { Tag } from "primereact/tag";
import "../../../../../../styles/customtable.scss";
import CustomImagePreview from "../../../../components/custom_imagepreview";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import backIcon from "../../../../../../assets/sports_assests/back.png";

const dayMapping = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};
const ViewDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const [copyLocation, setCopyLocation] = useState("");
  const toast = React.useRef(null);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(location)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Copied!",
          detail: "Location copied to clipboard.",
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error!",
          detail: "Failed to copy.",
        });
      });
  };

  const { id: sportsComplexId } = useParams();

  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );
  const { sportComplexByIdList, loading } = sportsComplexReducer;

  useEffect(() => {
    if (sportsComplexId) {
      dispatch(getSportComplexById(sportsComplexId));
    }
  }, [dispatch, sportsComplexId]);

  const {
    complexName,
    cityName,
    address,
    availableSports,
    isApproved,
    image,
    remarks,
    location,
  } = sportComplexByIdList || {};

  const getStatusTag = () => {
    if (isApproved === true) {
      return <Tag severity="success" value="Approved" />;
    } else if (isApproved === false) {
      return <Tag severity="danger" value="Rejected" />;
    } else {
      return <Tag severity="info" value="Pending" />;
    }
  };

  const handleCancelClick = () => {
    const redirectPath =
      user?.user?.role === "Admin" ? "/schedule-checker" : "/schedule";
    history.push(redirectPath);
  };

  return (
    <div className="main-form">
      <form>
        <div
          className="form-heading d-flex"
          style={{ alignItems: "center", gap: "10px" }}
        >
          <img
            src={backIcon}
            style={{ width: "30px", height: "30px", marginTop: "3px" }}
            onClick={handleCancelClick}
          />
          <h5>View Details</h5>
        </div>
        <div className="viewdetails">
          <div className="inner">
            <h6> Status:</h6>
            <p>{getStatusTag()}</p>
          </div>
          <div className="inner">
            <h6>Remarks:</h6>
            <p>{remarks ? remarks : "N/A"}</p>
          </div>

          <div className="p-fluid formgrid grid">
            <div className="field md:col-6 lg:col-4 xl:col-4 responsive-col disabled-classes">
              <label>City Name</label>
              <InputText
                maxLength={30}
                keyfilter={/^[a-zA-Z\s]*$/}
                name="cityId"
                value={cityName}
                disabled
              />
            </div>
            <div className="field md:col-6 lg:col-4 xl:col-4 responsive-col disabled-classes">
              <label>Complex Name</label>
              <InputText value={complexName} disabled />
            </div>

            <div className="field md:col-6 lg:col-4 xl:col-4 responsive-col">
              <label>Complex Image</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputText
                  value={image}
                  style={{ paddingRight: "2.5rem" }}
                  readOnly
                />
                <div
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "45%",
                    transform: "translateY(-50%)",
                    height: "2rem",
                    width: "2rem",
                    cursor: "pointer",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                >
                  <CustomImagePreview src={image} alt="Preview" />
                </div>
              </div>
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label>Address</label>
              <InputText value={address} />
            </div>

            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label>Location</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputText
                  name="location"
                  value={location}
                  onChange={(e) => setCopyLocation(e.target.value)}
                  style={{ paddingRight: "2.5rem" }}
                />
                <i
                  className="pi pi-clone"
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={handleCopy}
                />
              </div>
              <Toast ref={toast} />
            </div>
          </div>
          <div className="form-heading">
            <h5>Sport</h5>
          </div>
          <div className="p-fluid  formgrid grid">
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label>Sport Name</label>
              <InputText
                name="location"
                value={
                  availableSports && availableSports.length > 0
                    ? availableSports[0].sportName
                    : "N/A"
                }
              />
            </div>
          </div>
        </div>
        {availableSports && availableSports.length > 0 && (
          <div className="courts-map">
            {availableSports.map((sport, index) => (
              <div key={sport.sportId}>
                {sport.courts && sport.courts.length > 0 && (
                  <div>
                    {sport.courts.map((court, index) => (
                      <div key={court.courtId} className="mb-5 court-section">
                        <div
                          className="form-heading d-flex"
                          style={{ gap: "10px", alignItems: "center" }}
                        >
                          {" "}
                          <img
                            src={sport.sportImage}
                            style={{
                              borderRadius: "50px",
                              width: "40px",
                              height: "40px",
                              border: "2px solid #509793",
                            }}
                          />
                          <h5>Court {index + 1}</h5>
                        </div>
                        <div className="p-fluid  formgrid grid">
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Court Name </label>
                            <InputText value={court.courtName} />
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col disabled-classes">
                            <label>Court Photo</label>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <InputText
                                value={court.courtImage}
                                style={{ paddingRight: "2.5rem" }}
                                readOnly
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "0.5rem",
                                  top: "45%",
                                  transform: "translateY(-50%)",
                                  height: "2rem",
                                  width: "2rem",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              >
                                <CustomImagePreview
                                  src={court.courtImage}
                                  alt="Preview"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Amount Per Slot (PKR)</label>
                            <InputText value={court.slots.amountPerSlot} />
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Total Amount (PKR)</label>
                            <InputText value={court.slots.totalAmount} />
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Slot Timing Per Court (min)</label>
                            <InputText value={court.slots.timePerSlot} />
                          </div>

                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Start Time</label>
                            <InputText value={court.slots.startTime} />
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Refresh time (min)</label>
                            <InputText value={court.slots.refreshTime} />
                          </div>
                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Total Selectlots</label>
                            <InputText value={court.slots.totalSlots} />
                          </div>
                          {court.slotsData && court.slotsData.length > 0 && (
                            <div className="field md:col-6 lg:col-12 xl:col-12 responsive-col">
                              {" "}
                              <label>Slots</label>
                              <div className="mt-1 slots-container">
                                {court.slotsData.map((slot, index) => (
                                  <div key={index} className="slot-card">
                                    <div className="slot-header">
                                      <span className="slot-name">
                                        Slot {index + 1}
                                      </span>
                                      <input
                                        type="checkbox"
                                        name={`slot-checkbox-${index}`}
                                        className="slot-checkbox"
                                        checked={slot.isActive}
                                      />
                                    </div>
                                    <span className="slot-time">
                                      {slot.slotTime}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="field md:col-6 lg:col-12 xl:col-12 responsive-col">
                            <label>Available Days</label>
                            <div
                              className="d-flex"
                              style={{ flexWrap: "wrap" }}
                            >
                              {Object.keys(dayMapping).map((day) => (
                                <span
                                  key={day}
                                  className="day-checkbox"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    paddingRight: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Checkbox
                                    className="checkbox-courts"
                                    style={{ top: "0" }}
                                    checked={court.slots.daysAvailableArray.includes(
                                      day
                                    )}
                                    readOnly
                                  />
                                  {dayMapping[day]}
                                  {day !== "7" && " "}
                                </span>
                              ))}
                            </div>
                          </div>

                          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
                            <label>Additional Amount (PKR)</label>
                            <InputText value={court.slots.additionalCharges} />
                          </div>
                        </div>
                        <p style={{ fontSize: "12px", color: "green" }}>
                          Note: Amount + additional amount applies only on
                          weekends.
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </form>
    </div>
  );
};

export default ViewDetails;
