import { configureStore } from "@reduxjs/toolkit";
import login_user_slice from "./redux/auth_slice/login_user_slice";
import sportsComplex_slice from "./redux/zsports_slice/zsports_slice";
import bookingHistory_slice from "./redux/zsports_slice/booking_history_slice";
import sportsComplexes_slice from "./redux/zsports_slice/adminsports_slice";
import user_slice from "./redux/user_slice/user_slice";
import register_user_slice from "./redux/auth_slice/register_user_slice";
import dashboard_slice from "./redux/dashboard_slice/dashboard_slice";
import admin_slice from "./redux/user_slice/admin_slice";
import teller_slice from "./redux/user_slice/teller_slice";
import portal_slice from "./redux/portal_slice";


const initialState = {};

const store = configureStore({
  reducer: {
    loginUser: login_user_slice,
    users: user_slice,
    sportsComplex: sportsComplex_slice,
    bookingHistory: bookingHistory_slice,
    sportsComplexes: sportsComplexes_slice,
    register: register_user_slice,
    dashboard: dashboard_slice,
    adminManagement: admin_slice,
    tellerManagement: teller_slice,
    portal: portal_slice,
  },
  preloadedState: initialState,
});

export default store;
