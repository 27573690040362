import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import config from "../../../../../config";
import ActionButtons from "../../../components/action_buttons";
import {
  createAdmin,
  updateAdmin,
  getAdminList,
} from "../../../../redux/user_slice/admin_slice";
import { Password } from "primereact/password";
import CustomImagePreview from "../../../components/custom_imagepreview";
import { Dropdown } from "primereact/dropdown";

const EditAddForm = ({ onHide, formData, currentPage, rowsPerPage }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginUser);

  const [isImageEdit, setIsImageEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [fileName, setFileName] = useState(
    formData?.profileImagePath ? formData?.profileImagePath : ""
  );

  const formik = useFormik({
    initialValues: {
      name: formData?.name || "",
      email: formData?.email || "",
      mobileNumber: formData?.phoneNumber || "",
      isActive: formData?.isActive.toString(),
      pin: "",
      newPin: "",
      profileImagePath: formData?.profileImagePath
        ? formData.profileImagePath
        : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .required("Mobile Number is required.")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long."
        )
        .length(11, "Mobile Number must be 11 digits long."),
      ...(formData
        ? {}
        : {
            pin: Yup.string()
              .required("PIN is required.")
              .length(4, "PIN must be 4 digits long."),
          }),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      if (!formChanged) {
        onHide();
        return;
      }

      const updatePayload = {
        id: formData?.id,
        ...values,
        isActive: values.isActive === "true",
      };

      try {
        const action = formData
          ? updateAdmin({ ...updatePayload, token: user?.token })
          : createAdmin({ ...values, token: user?.token });

        const response = await dispatch(action).unwrap();
        await dispatch(
          getAdminList({
            token: user.token,
            page: currentPage,
            recordsPerPage: rowsPerPage,
          })
        ).unwrap();

        onHide();
      } catch (error) {
        console.error("Error in form submission:", error);
        console.error("Error details:", error.response?.data || error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFieldChange = (field, value) => {
    formik.setFieldValue(field, value);
    setFormChanged(true);
  };

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        let base64String = reader.result;
        if (base64String.includes("base64,")) {
          base64String = base64String.split("base64,")[1];
        }
        setFileName(file.name);
        setIsImageEdit(true);
        handleFieldChange("profileImageBase64", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const activeOptions = [
    { label: "Active", value: "true" },
    { label: "Non-active", value: "false" },
  ];

  return (
    <>
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-6 responsive-col">
              <label htmlFor="name">
                Name
                {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                id="name"
                maxLength={30}
                value={formik.values.name}
                placeholder="Add name"
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
              {formik.touched.name && formik.errors.name && (
                <small className="p-error">{formik.errors.name}</small>
              )}
            </div>
            {!formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="mobileNumber">
                  Mobile Number{" "}
                  {!formData && <span className="asteric">&nbsp;*</span>}
                </label>
                <InputText
                  maxLength={11}
                  id="mobileNumber"
                  keyfilter="num"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("mobileNumber", sanitizedValue);
                  }}
                  className={
                    formik.errors.mobileNumber && formik.touched.mobileNumber
                      ? "p-invalid"
                      : ""
                  }
                />
                {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                  <small className="p-error">
                    {formik.errors.mobileNumber}
                  </small>
                )}
              </div>
            )}
            {!formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="pin">
                  Pin {!formData && <span className="asteric">&nbsp;*</span>}
                </label>
                <Password
                  toggleMask
                  autoComplete="new-password"
                  id="pin"
                  name="pin"
                  feedback={false}
                  maxLength={4}
                  placeholder="Enter pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  onKeyUp={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("pin", sanitizedValue);
                  }}
                />
                {formik.errors.pin && formik.touched.pin && (
                  <small className="p-error">{formik.errors.pin}</small>
                )}
              </div>
            )}
            {formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="newPin">New Pin</label>
                <Password
                  toggleMask
                  autoComplete="new-password"
                  id="newPin"
                  name="newPin"
                  feedback={false}
                  maxLength={4}
                  placeholder="Enter new pin"
                  value={formik.values.newPin}
                  onChange={(e) => handleFieldChange("newPin", e.target.value)}
                  onKeyUp={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    handleFieldChange("newPin", sanitizedValue);
                  }}
                />
              </div>
            )}
            <div className="field md:col-6 responsive-col">
              <label htmlFor="email">
                Email {!formData && <span className="asteric">&nbsp;*</span>}
              </label>
              <InputText
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formik.values.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                className={
                  formik.errors.email && formik.touched.email ? "p-invalid" : ""
                }
              />
              {formik.errors.email && formik.touched.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>

            {formData && (
              <div className="field md:col-6 responsive-col">
                <label htmlFor="isActive">Status</label>
                <Dropdown
                  id="isActive"
                  name="isActive"
                  options={activeOptions}
                  placeholder="Select status"
                  value={formik.values.isActive}
                  onChange={(e) =>
                    handleFieldChange("isActive", e.value.toString())
                  }
                />
              </div>
            )}

            <div className="field md:col-6 responsive-col">
              <label htmlFor="ProfileImageFile">Profile Image</label>

              <div className="file-input">
                <input
                  type="text"
                  name="profileImageFile"
                  readOnly
                  value={fileName}
                  placeholder="No file chosen"
                />
                <label
                  htmlFor="ProfileImageFile"
                  className="choose-file-button"
                >
                  Choose File
                </label>
                <input
                  name="profileImageFile"
                  type="file"
                  id="ProfileImageFile"
                  accept=".png, .jpg, .jpeg, .svg"
                  onChange={handleFileUpload}
                  className="choose-image-none"
                />
              </div>
              {/* {formData?.profileImagePath && (
                <div className="preview-image">
                  <CustomImagePreview
                    src={formData.profileImagePath}
                    alt="Profile"
                  />
                </div>
              )} */}
            </div>
          </div>
          <div className="mt-5">
            <ActionButtons
              showSave
              onCancel={onHide}
              onSave={formik.handleSubmit}
              loading={formik.isSubmitting}
              saveLabel={formData ? "Update" : "Add"}
              cancelLabel="Close"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
