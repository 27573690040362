import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function OverallBarChart({
  dashboardList
}) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: ["Profile Management", "Schedule Management", "Complex management"],
      datasets: [
        {
          label: "Profile Management",
          data: [dashboardList?.activeUsersCount, 0, 0],
          backgroundColor: "#FFC107",
          borderColor: "#FFC107",
          barThickness: 60,
        },
        {
          label: "Schedule Management",
          data: [0, 800, 0],
          data: [0, dashboardList?.totalBookings, 0],
          backgroundColor: "#B2EBF2",
          borderColor: "#B2EBF2",
          borderWidth: 1,
          barThickness: 60,
        },
        {
          label: "Complex management",
          data: [0, 0, dashboardList?.totalComplexes],
          backgroundColor: "#9FA8DA",
          borderColor: "#9FA8DA",
          borderWidth: 1,
          barThickness: 60,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: 2000,
        },
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          stacked: false,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: false,
            boxWidth: 15,
            padding: 30,
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },
      responsive: true,
      aspectRatio: 2,
    };

    setChartData(data);
    setChartOptions(options);
  }, [dashboardList]);

  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
