import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getBookingHistory } from "../../../redux/zsports_slice/booking_history_slice";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import { Tag } from "primereact/tag";
import Loader from "../../components/loader";
import moment from "moment";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function BookingHistoryScreen() {
  const bookingHistoryReducer = useSelector(
    (state) => state.bookingHistory || {}
  );
  const { bookingHistoryList, loading } = bookingHistoryReducer;
  const { user } = useSelector((state) => state.loginUser);

  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedNestedRows, setExpandedNestedRows] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const getStartOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1)
      .toISOString()
      .split("T")[0];
  };

  const getEndOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0)
      .toISOString()
      .split("T")[0];
  };

  useEffect(() => {
    if (user && user.user && user.user.mobileNumber) {
      const userMobileNumber = user.user.mobileNumber;

      const requestBody = {
        mobileNumber: userMobileNumber,
        startDate: getStartOfMonth(),
        endDate: getEndOfMonth(),
      };

      dispatch(getBookingHistory(requestBody));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (bookingHistoryList && bookingHistoryList.zSportHistoryList) {
      const completedBookings = bookingHistoryList.zSportHistoryList.filter(
        (booking) => booking.status === "COMPLETED"
      );
      setFilteredList(completedBookings);
    }
  }, [bookingHistoryList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filteredData = bookingHistoryList.zSportHistoryList?.filter(
      (list) =>
        list.status === "COMPLETED" &&
        (list.location.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.court.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.sports.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredList(filteredData);
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.image} />
      </div>
    );
  };

  const bookingDateTemplate = (rowData) => {
    return formatDate(rowData.bookingDate);
  };

  const scanDateTemplate = (rowData) => {
    if (rowData.scanDate === null) {
      return "N/A";
    }
    return formatDate(rowData.scanDate);
  };

  const formatDate = (value) => {
    return moment(value).format("MMMM Do YYYY");
  };

  const columnsData = [
    {
      field: "date",
      header: "Booking Date",
      body: bookingDateTemplate,
    },
    {
      field: "court",
      header: "Court Name",
    },
    {
      field: "location",
      header: "Complex Name",
    },
    {
      field: "mobileNumber",
      header: "Mobile Number",
    },
    {
      field: "sports",
      header: "Sports",
    },
    {
      field: "price",
      header: "Price (PKR)",
      body: (rowData) => {
        return `${rowData.price.toString().toUpperCase()} `;
      },
    },
    {
      field: "scanDate",
      header: "Scan Date",
      body: scanDateTemplate,
    },

    {
      field: "status",
      header: "Status",
      body: (rowData) => {
        if (rowData.status === "COMPLETED") {
          return <Tag severity="success" value={rowData.status} />;
        }
        return null;
      },
    },
  ];

  const nestedColumnsData = [
    {
      field: "slotName",
      header: "Slot Name",
    },
    {
      field: "slotPrice",
      header: "Slot Price (PKR)",
    },
    {
      field: "slotStatus",
      header: "Slot Status",
    },
    {
      field: "slotTime",
      header: "Slot Time",
    },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.timeSlots}>
          {nestedColumnsData.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view "
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive"></div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filteredList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
