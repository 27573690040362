import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  adminList: [],
  loading: false,
  error: null,
  totalRecords: 0,
  currentPage: 1,
  pageSize: 10,
  updateSuccess: null,
};

// Slice definition
const adminReducer = createSlice({
  name: "adminManagement",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.addSuccess = action.payload;
        toast.success("Admin added successfully");
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = action.payload;
        toast.success("Profile details updated successfully");
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAdminList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.loading = false;
        state.adminList = action.payload.data;
        state.totalRecords = action.payload.recordsTotal;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteSuccess = action.payload;
        state.adminList = state.adminList.filter(
          (admin) => admin.id !== action.payload
        );
        toast.success("Admin Inactive successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAdminById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.adminList = action.payload;
      })
      .addCase(getAdminById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reducer and actions
export default adminReducer.reducer;
export const { resetChangeStatus } = adminReducer.actions;

export const getAdminList = createAsyncThunk(
  "adminManagement/getAdminList",
  async (
    { token, pageNo, pageSize, searchText, isActive },
    { rejectWithValue }
  ) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.checkerAppPath}/GetAdminsList`,
        { pageNo, pageSize, searchText, isActive },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "adminManagement/deleteUser",
  async ({ adminId, token }, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.checkerAppPath}/DeleteUser/${adminId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return adminId;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const getAdminById = createAsyncThunk(
  "adminManagement/getAdminListId",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.checkerAppPath}/GetAdminById/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const createAdmin = createAsyncThunk(
  "adminManagement/createAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;
      const response = await Axios.post(
        `${config.baseUrl}${config.checkerAppPath}/CreateAdmin`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "adminManagement/updateAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;
      const response = await Axios.put(
        `${config.baseUrl}${config.checkerAppPath}/UpdateAdmin`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
