import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";

const SlotForm = ({ slotsData, setFieldValue, courtIndex, sportIndex }) => {
  return (
    <FieldArray
      name={`availableSports[${sportIndex}].courts[${courtIndex}].slotsData`}
    >
      {({ push, remove, form }) => (
        <div className="field md:col-6 lg:col-12 xl:col-12 responsive-col">
           <label>Slots Details</label>
          <div className="slots-container">
            {slotsData.map((slot, slotIndex) => (
              <div key={slotIndex} className="slot-card">
                <div className="slot-header">
                  <span className="slot-name">Slot {slotIndex + 1}</span>
                  <input
                    type="checkbox"
                    name={`availableSports[0].courts[${courtIndex}].slotsData[${slotIndex}]`}
                    className="slot-checkbox"
                    checked={slot.isActive}
                    onChange={(e) => {
                      setFieldValue(
                        `availableSports[0].courts[${courtIndex}].slotsData[${slotIndex}].isActive`,
                        e.target.checked
                      );
                    }}
                  />
                </div>
                <span className="slot-time">{slot?.slotTime}</span>

              </div>
              
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};

export default SlotForm;
